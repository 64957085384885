const colors = {
	primary: '#784c99',
	secondary: '#3f3f3f',
	tertiary: '#fcbe5e',
};

export default {
	colors,
	hero: {
		tagline: 'agency_byom_tagline',
		title: '',
		description: 'agency_byom_hero_description',
		searchName: 'Byom',
		logo: 'hero/byom_logo.png',
		backgroundImage: 'hero/byom_pattern.png',
		interval: 10000, // 10 seconds
		variantImages: [
			{
				sku: 'BY-1011',
				image: 'hero/byom_strawberry.png',
			},
		],
	},
	summary: {
		title: 'byom_summary_title',
		description: 'byom_summary_description',
		image: 'summary/byom-summary.png',
	},
	overview: {
		iconCols: 5,
		background: 'background-color: #784c99',
		textStyle: 'color: #ffffff',
		icons: [
			{
				text: 'byom_summary_icon_1',
				file: 'summary/icons/ph_balance.svg',
			},
			{
				text: 'byom_summary_icon_2',
				file: 'summary/icons/digestive_health.svg',
			},
			{
				text: 'byom_summary_icon_3',
				file: 'summary/icons/cellular_hydration.svg',
			},
			{
				text: 'byom_summary_icon_4',
				file: 'summary/icons/immune_system.svg',
			},
			{
				text: 'byom_summary_icon_5',
				file: 'summary/icons/body_fight_bacteria.svg',
			},
		],
	},
	accordion: [
		{ title: 'byom_overview', content: 'byom_overview_content', image: 'overview/byom-girl.png' },
		{ title: 'byom_probiotics_power', content: 'byom_probiotics_power_content' },
		{ title: 'byom_cfu', content: 'byom_cfu_content' },
	],
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/840622464?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/840622464?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/840622464?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/natasha-r.png',
				author: 'Natasha R., USA',
				content: 'byom_review_1',
				stars: 5,
			},
			{
				image: 'reviews/testimonial_thressa.png',
				author: 'Thressa S., USA',
				content: 'byom_review_2',
				stars: 5,
			},
		],
	},
	buy: {
		background: '#784c99',
		searchName: 'Byom',
	},
	disclaimer: {
		text: 'lite_disclaimer',
		text2: 'disclaimer_statements_not_evaluated',
	},
};
