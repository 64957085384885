const colors = {
	primary: '#65B0E6',
	secondary: '#65B0E6',
	tertiary: '#7C7C7C',
};

export default {
	colors,
	hero: {
		tagline: 'agency_zlem_tagline',
		title: '',
		description: 'agency_zlem_hero_description',
		searchName: 'Zlem',
		logo: 'hero/zlem_logo.png',
		backgroundImage: 'hero/zlem_pattern.png',
		interval: 10000, // 10 seconds
		variantImages: [
			{
				sku: 'ZL-T1004',
				image: 'hero/zlem_red_velvet.png',
			},
			{
				sku: 'ZL-1007',
				image: 'hero/zlem_chocolate_strawberry.png',
			},
		],
	},
	overview: {
		iconCols: 6,
		background: 'background-color: #ffffff',
		icons: [
			{
				text: 'zlem_overview_icon_1',
				file: 'overview/icons/morning-icon.png',
			},
			{
				text: 'zlem_overview_icon_2',
				file: 'overview/icons/slim-icon.png',
			},
			{
				text: 'zlem_overview_icon_3',
				file: 'overview/icons/mood-icon.png',
			},
			{
				text: 'zlem_overview_icon_4',
				file: 'overview/icons/arm-icon.png',
			},
			{
				text: 'zlem_overview_icon_5',
				file: 'overview/icons/sleep-icon.png',
			},
			{
				text: 'zlem_overview_icon_6',
				file: 'overview/icons/energy-icon.png',
			},
		],
	},
	stages: {
		intro: 'zlem_stages_intro',
		stages: [
			{
				image: 'stages/zlem_snap_1.png',
				backgroundImage: 'stages/zlem_pattern.png',
				title: 'zlem_stage_1_title',
				description: 'zlem_stage_1_description',
			},
			{
				image: 'stages/zlem_snap_2.png',
				backgroundImage: 'stages/zlem_pattern.png',
				title: 'zlem_stage_2_title',
				description: 'zlem_stage_2_description',
			},
		],
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/727113460?h=fbc2f68c3c&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/830597919?h=fbc2f68c3c&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/727113460?h=fbc2f68c3c&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/shanna-b.png',
				author: 'Shanna B., USA',
				content: 'zlem_review_1',
				stars: 5,
			},
			{
				image: 'reviews/emily-c.png',
				author: 'Emily C., USA',
				content: 'zlem_review_2',
				stars: 5,
			},
			{
				image: 'reviews/thressa-s.png',
				author: 'Thressa S., USA',
				content: 'zlem_review_3',
				stars: 5,
			},
			{
				image: 'reviews/brett-h.png',
				author: 'Brett H., Florida',
				content: 'zlem_review_4',
				stars: 5,
			},
			{
				image: 'reviews/rory-r.png',
				author: 'Rory R., Utah',
				content: 'zlem_review_5',
				stars: 5,
			},
		],
	},
	buy: {
		background: '#65B0E6',
		searchName: 'Zlem',
	},
	disclaimer: {
		text: 'lite_disclaimer',
		text2: 'disclaimer_statements_not_evaluated',
	},
};
