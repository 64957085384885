const colors = {
	primary: '#88939E',
	secondary: '#88939E',
	tertiary: '#7C7C7C',
};

export default {
	colors,
	hero: {
		tagline: 'agency_tuun_tagline',
		title: '',
		description: 'agency_tuun_hero_description',
		searchName: 'Tuun',
		logo: 'hero/tuun_logo.png',
		backgroundImage: 'hero/tuun_pattern.png',
		interval: 10000, // 10 seconds
		variantImages: [
			{
				sku: 'TN-RG',
				text: 'RG_material_type',
				image: 'hero/tuun_rose_gold.png',
			},
			{
				sku: 'TN-BS',
				text: 'BS_material_type',
				image: 'hero/tuun_black.png',
			},
			{
				sku: 'TN-SD',
				text: 'SD_material_type',
				image: 'hero/tuun_swarovski.png',
			},
		],
	},
	overview: {
		iconCols: 6,
		background: 'background-color: #ffffff',
		icons: [
			{
				text: 'tuun_overview_icon_1',
				file: 'overview/icons/benefits-1.png',
			},
			{
				text: 'tuun_overview_icon_2',
				file: 'overview/icons/benefits-2.png',
			},
			{
				text: 'tuun_overview_icon_3',
				file: 'overview/icons/benefits-3.png',
			},
			{
				text: 'tuun_overview_icon_4',
				file: 'overview/icons/benefits-4.png',
			},
			{
				text: 'tuun_overview_icon_5',
				file: 'overview/icons/benefits-5.png',
			},
			{
				text: 'tuun_overview_icon_6',
				file: 'overview/icons/benefits-6.png',
			},
		],
	},
	benefits: {
		sections: [
			{
				reversed: false,
				image: 'overview/girl-with-tuun.png',
				title: 'tuun_section_1_title',
				content: 'tuun_section_1_content',
			},
		],
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/830658278?h=2d15b39bc6&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/830658278?h=2d15b39bc6&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/830658278?h=2d15b39bc6&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/mary-amoedo.png',
				author: 'Mary A, USA',
				content: 'tuun_review_2',
				stars: 5,
			},
			{
				image: 'reviews/natasha-r.png',
				author: 'Natasha R., USA',
				content: 'tuun_review_3',
				stars: 5,
			},
			{
				image: 'reviews/tymiesha-m.png',
				author: 'Tymiesha M., USA',
				content: 'tuun_review_4',
				stars: 5,
			},
			{
				image: 'reviews/rory-r.png',
				author: 'Rory R, USA',
				content: 'tuun_review_5',
				stars: 5,
			},
			{
				image: 'reviews/kaneko-k.png',
				author: 'Kaneko K., Japan',
				content: 'tuun_review_1',
				stars: 5,
			},
		],
	},
	buy: {
		background: '#88939E',
		searchName: 'Tuun',
	},
	disclaimer: {
		text: 'lite_disclaimer',
		text2: 'disclaimer_statements_not_evaluated',
	},
};
