const colors = {
	primary: '#DF703D',
	secondary: '#DF703D',
	tertiary: '#7C7C7C',
};

export default {
	colors,
	hero: {
		tagline: 'agency_bran_tagline',
		title: '',
		description: 'agency_bran_hero_description',
		searchName: 'Bran',
		logo: 'hero/bran_logo.png',
		backgroundImage: 'hero/bran_pattern.png',
		interval: 10000, // 10 seconds
		variantImages: [
			{
				sku: 'BR-1002',
				image: 'hero/bran_caramel_macchiato.png',
			},
			{
				sku: 'BR-1003',
				image: 'hero/bran_chocolate_sea_salt.png',
			},
			{
				sku: 'BR-1008',
				image: 'hero/bran_chocolate_mint.png',
			},
			{
				sku: 'BR-1010',
				image: 'hero/bran_peanut_butter_cup.png',
			},
			{
				sku: 'BR-1001',
				image: 'hero/bran_lemon_drop.png',
			},
		],
	},
	overview: {
		iconCols: 5,
		background: 'background-color: #ffffff',
		icons: [
			{
				text: 'bran_overview_icon_1',
				file: 'overview/icons/brain-icon.png',
			},
			{
				text: 'bran_overview_icon_3',
				file: 'overview/icons/eye-icon.png',
			},
			{
				text: 'bran_overview_icon_4',
				file: 'overview/icons/mood-icon.png',
			},
			{
				text: 'bran_overview_icon_5',
				file: 'overview/icons/idea-icon.png',
			},
			{
				text: 'bran_overview_icon_6',
				file: 'overview/icons/yoga-icon.png',
			},
		],
	},
	benefits: {
		sections: [
			{
				reversed: false,
				image: 'benefits/benefits-1.jpg',
				title: 'bran_benefits_title',
				content: 'bran_benefits_left_content',
			},
		],
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/727111655?h=de12fdb718&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/921141761?h=de12fdb718&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/727111655?h=de12fdb718&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/heather-b.png',
				author: 'Heather B., USA',
				content: 'bran_review_1',
				stars: 5,
			},
			{
				image: 'reviews/cheryl-w.png',
				author: 'Cheryl W., USA',
				content: 'bran_review_2',
				stars: 5,
			},
			{
				image: 'reviews/tracy-p.png',
				author: 'Tracy P., USA',
				content: 'bran_review_3',
				stars: 5,
			},
			{
				image: 'reviews/sebastian-l.png',
				author: 'Sebastián L., Colombia',
				content: 'bran_review_4',
				stars: 5,
			},
			{
				image: 'reviews/nell-g.png',
				author: 'Nell G., USA',
				content: 'bran_review_5',
				stars: 5,
			},
			{
				image: 'reviews/sam-y.png',
				author: 'Sam Y., USA',
				content: 'bran_review_6',
				stars: 5,
			},
		],
	},
	buy: {
		background: '#DF703D',
		searchName: 'Bran',
	},
	disclaimer: {
		text: 'lite_disclaimer',
		text2: 'disclaimer_statements_not_evaluated',
	},
};
