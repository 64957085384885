const colors = {
	primary: '#e73340',
	secondary: '#a12b2a',
	tertiary: '#7C7C7C',
};

export default {
	colors,
	hero: {
		tagline: 'agency_plos_tagline',
		title: '',
		description: 'agency_plos_hero_description',
		searchName: 'Plos',
		logo: 'hero/plos_logo.png',
		backgroundImage: 'hero/plos_pattern.png',
		interval: 10000, // 10 seconds
		variantImages: [
			{
				sku: 'PL-1012',
				image: 'hero/plos_mocha.png',
			},
		],
	},
	overview: {
		iconCols: 6,
		background: 'background-color: #ffffff',
		icons: [
			{
				text: 'plos_summary_icon_1',
				file: 'summary/icons/healthy-weight-icon.png',
			},
			{
				text: 'plos_summary_icon_2',
				file: 'summary/icons/appetite-icon.png',
			},
			{
				text: 'plos_summary_icon_3',
				file: 'summary/icons/healthy-metabolism-icon.png',
			},
			{
				text: 'plos_summary_icon_4',
				file: 'summary/icons/energy-icon.png',
			},
			{
				text: 'plos_summary_icon_5',
				file: 'summary/icons/lipid-synthesis-icon.png',
			},
			{
				text: 'plos_summary_icon_6',
				file: 'summary/icons/metabolism-icon.png',
			},
		],
	},
	benefits: {
		sections: [
			{
				reversed: false,
				image: 'overview/overview-1.png',
				title: 'bran_benefits_title',
				content: 'plos_tabs_benefits_content',
			},
		],
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/727154135?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/840622649?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/727154135?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/ronald-t.png',
				author: 'Ronald T., USA',
				content: 'plos_review_1',
				stars: 5,
			},
			{
				image: 'reviews/callie-a.png',
				author: 'Callie A., USA',
				content: 'plos_review_2',
				stars: 5,
			},
			{
				image: 'reviews/richard-a.png',
				author: 'Richard A., USA',
				content: 'plos_review_3',
				stars: 5,
			},
			{
				image: 'reviews/dami-p.png',
				author: 'Dami P., USA',
				content: 'plos_review_4',
				stars: 5,
			},
		],
	},
	buy: {
		background: '#e73340',
		searchName: 'Plos',
	},
	disclaimer: {
		text: 'lite_disclaimer',
		text2: 'disclaimer_statements_not_evaluated',
	},
};
