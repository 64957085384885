import Bran from '@/settings/Agency/LandingPages/Bran';
import Zlem from '@/settings/Agency/LandingPages/Zlem';
import Uuth from '@/settings/Agency/LandingPages/Uuth';
import Plos from '@/settings/Agency/LandingPages/Plos';
import Byom from '@/settings/Agency/LandingPages/Byom';
import Tuun from '@/settings/Agency/LandingPages/Tuun';

export const COLORS = {
	title: '#DF703D',
	text: '#7C7C7C',
	bran: '#DF703D',
	zlem: '#65B0E6',
	uuth: '#9FC642',
	plos: '#e73340',
	byom: '#784c99',
	tuun: '#88939E',
};

export const contentStyles = (highlightColor) => ({
	b: {
		class: 'font-weight-bolder',
		style: { color: highlightColor },
	},
});

export const PRODUCT_BADGES = {
	bran: 'bran.png',
	zlem: 'zlem.png',
	uuth: 'uuth.png',
	plos: 'plos.png',
	byom: 'byom.png',
};

export const PRODUCT_REVIEWS = (() => {
	const allReviewSectionData = {
		Bran: { ...Bran.reviews },
		Zlem: { ...Zlem.reviews },
		Uuth: { ...Uuth.reviews },
		Plos: { ...Plos.reviews },
		Byom: { ...Byom.reviews },
		Tuun: { ...Tuun.reviews },
	};

	return Object.keys(allReviewSectionData).reduce((acc, key) => {
		const reviewSectionData = allReviewSectionData[key];
		const color = COLORS[key.toLowerCase()];

		const reviews = reviewSectionData.reviews.map((review) => ({
			...review,
			color,
		}));

		const newReviewSectionData = {
			...reviewSectionData,
			titleColor: COLORS[key.toLowerCase()],
			textColor: COLORS.text,
			buttonColor: COLORS[key.toLowerCase()],
			reviews,
		};

		return {
			...acc,
			[key]: newReviewSectionData,
		};
	}, {});
})();

export const AGENCY_STORE_REVIEWS = {
	titleColor: COLORS.title,
	textColor: COLORS.text,
	reviews: [
		{
			color: COLORS.bran,
			image: 'bran/reviews/heather-b.png',
			author: 'Heather, B., USA',
			content: 'agency_bran_review_1',
			stars: 5,
		},
		{
			color: COLORS.bran,
			image: 'bran/reviews/cheryl-w.png',
			author: 'Cheryl, W., USA',
			content: 'agency_bran_review_2',
			stars: 5,
		},
		{
			color: COLORS.zlem,
			image: 'zlem/reviews/shanna-b.png',
			author: 'Shanna, B., USA',
			content: 'agency_zlem_review_1',
			stars: 5,
		},
		{
			color: COLORS.zlem,
			image: 'zlem/reviews/thressa-s.png',
			author: 'Thressa, S., USA',
			content: 'agency_zlem_review_2',
			stars: 5,
		},
		{
			color: COLORS.uuth,
			image: 'uuth/reviews/shanna-b.png',
			author: 'Shanna, B., USA',
			content: 'agency_uuth_review_1',
			stars: 5,
		},
		{
			color: COLORS.uuth,
			image: 'uuth/reviews/deana-h.png',
			author: 'Deana, H., USA',
			content: 'agency_uuth_review_2',
			stars: 5,
		},
		{
			color: COLORS.plos,
			image: 'plos/reviews/richard-a.png',
			author: 'Richard, A., USA',
			content: 'agency_plos_review_1',
			stars: 5,
		},
		{
			color: COLORS.plos,
			image: 'plos/reviews/dami-p.png',
			author: 'Dami, P., USA',
			content: 'agency_plos_review_2',
			stars: 5,
		},
		{
			color: COLORS.byom,
			image: 'byom/reviews/natasha-r.png',
			author: 'Natasha, R., USA',
			content: 'agency_byom_review_1',
			stars: 5,
		},
		{
			color: COLORS.byom,
			image: 'byom/reviews/thressa-s.png',
			author: 'Thressa, S., USA',
			content: 'agency_byom_review_2',
			stars: 5,
		},
		{
			color: COLORS.tuun,
			image: 'tuun/reviews/kaneko-k.png',
			author: 'Kaneko, K., Japan',
			content: 'agency_tuun_review_1',
			stars: 5,
		},
		{
			color: COLORS.tuun,
			image: 'tuun/reviews/tymiesha-m.png',
			author: 'Tymiesha, M., USA',
			content: 'agency_tuun_review_2',
			stars: 5,
		},
	],
};

export default {};
