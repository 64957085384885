const colors = {
	primary: '#9FC642',
	secondary: '#9FC642',
	tertiary: '#7C7C7C',
};

export default {
	colors,
	hero: {
		tagline: 'agency_uuth_tagline',
		title: 'agency_uuth_hero_title',
		description: 'agency_uuth_hero_description',
		searchName: 'Uuth',
		logo: 'hero/uuth_logo.png',
		backgroundImage: 'hero/uuth_pattern.png',
		interval: 10000, // 10 seconds
		variantImages: [
			{
				sku: 'UT-1005',
				image: 'hero/uuth_supperberry.png',
			},
		],
	},
	overview: {
		iconCols: 5,
		background: 'background-color: #ffffff',
		icons: [
			{
				text: 'uuth_overview_icon_1',
				file: 'overview/icons/youthfull-icon.png',
			},
			{
				text: 'uuth_overview_icon_2',
				file: 'overview/icons/lines-icon.png',
			},
			{
				text: 'uuth_overview_icon_3',
				file: 'overview/icons/heart-icon.png',
			},
			{
				text: 'uuth_overview_icon_4',
				file: 'overview/icons/arm-icon.png',
			},
			{
				text: 'uuth_overview_icon_5',
				file: 'overview/icons/stress-icon.png',
			},
		],
	},
	benefits: {
		sections: [
			{
				reversed: false,
				image: 'benefits/benefits-1.jpg',
				title: 'uuth_benefits_title',
				content: 'uuth_benefits_left_content',
			},
		],
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/727155489?h=e8ad93e197&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/834221606?h=e8ad93e197&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/727155489?h=e8ad93e197&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/rory-r.png',
				author: 'Rory, R., USA',
				content: 'uuth_review_1',
				stars: 5,
			},
			{
				image: 'reviews/shanna-b.png',
				author: 'Shanna, B., USA',
				content: 'uuth_review_3',
				stars: 5,
			},
			{
				image: 'reviews/holley-s.png',
				author: 'Holley, S., USA',
				content: 'uuth_review_2',
				stars: 5,
			},
			{
				image: 'reviews/dami-p.png',
				author: 'Dami P., USA',
				content: 'uuth_review_4',
				stars: 5,
			},
			{
				image: 'reviews/deana-h.png',
				author: 'Deana H., USA',
				content: 'uuth_review_5',
				stars: 5,
			},
			{
				image: 'reviews/tanya-r.png',
				author: 'Tanya R., USA',
				content: 'uuth_review_6',
				stars: 5,
			},
		],
	},
	buy: {
		background: '#9FC642',
		searchName: 'Uuth',
	},
	disclaimer: {
		text: 'lite_disclaimer',
		text2: 'disclaimer_statements_not_evaluated',
	},
};
